<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box">
        <div class="tipOne">{{ $t("personnelTraining.PT_tipOne") }}</div>
        <div class="tipTwo">{{ $t("personnelTraining.PT_tipTwo") }}</div>
        <div class="tipOne">{{ $t("personnelTraining.PT_tipThree") }}</div>
        <div class="modular">
          <div v-for="(item, index) in $t('personnelTraining.PT_modular')" :key="index" class="modularItem">
            <div :class="
              index == $t('personnelTraining.PT_modular').length - 1 &&
                $i18n.locale == 'en'
                ? 'Itemdiv'
                : ''
            "> {{ item.name }} </div>
            <img :src="item.icon" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="imgTwo settingStyle" :style="
      'backgroundImage:url(' + require('@/assets/img/ptBJ1.png') + ')'
    ">
      <div>{{ $t('personnelTraining.PT_tipFour') }}</div>
      <!-- <img src="@/assets/img/ptBJ1.png" alt="" class="imgTwo" /> -->
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-人才培养
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-16 14:29
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "人才培养",
          title_en: "personnel training",
          path: "",
        },
      ],
      bannerImg: "",
    };
  },
  mounted() {
    this.getBigPicture();
  },
  methods: {
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 1, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px;
  background: #f6f6f6;
}

.bigPicture {
  width: 100%;
  height: 400px;
}

.box {
  padding: 80px 49px 63px;

  .tipOne {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .tipTwo {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height: 30px;
    margin-bottom: 79px;
  }

  .tipThree {
    font-size: 36px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .modular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .modularItem {
      background: linear-gradient(0deg, #1a2a60 0%, #1a2a60 100%);
      width: 31%;
      height: 240px;
      position: relative;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      >div {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        padding: 10px 96px;
        text-align: center;
      }

      .Itemdiv {
        padding: 10px 22px !important;
      }

      >img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 75px;
        height: 70px;
      }
    }
  }
}

.imgTwo {
  width: 100%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    padding: 0 100px;
    font-size: 34px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
  }
}
</style>